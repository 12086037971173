import auth0 from 'auth0-js';
import noop from 'lodash/noop';

export default function useSocialAuthPopup() {
  const webAuth = new auth0.WebAuth({
    domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    clientID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  });

  return {
    showPopup: (
      connection: 'google-oauth2',
      callback?: auth0.Auth0Callback<auth0.Auth0Result, auth0.Auth0Error>
    ) => {
      webAuth.popup.authorize(
        {
          redirectUri: `${process.env.NEXT_PUBLIC_APP_BASE_URL}/api/auth/callback?redirectTo=/popup-auth&popup=true`,
          connection,
          responseType: 'code',
          state: 'idontknowhowthisworkshelp',
          domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
          audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE
        },
        callback || noop
      );
    },
    webAuth,
  };
}
