import { trpc } from '@/utils/trpc';
// import * as amplitude from '@amplitude/analytics-browser';

export function useSignUpContinue() {
  const { mutateAsync: track } = trpc.useMutation('event.track');
  const signUpContinueTrack = () => {
    track({
      event_type: 'Signup Continue',
      event_data: { screen: 'Signup' },
    });
    // amplitude.track('Signup Continue');
  };
  return {
    signUpContinueTrack,
  };
}
