import { trpc } from '@/utils/trpc';
// import OneSignal from 'react-onesignal';

export default function useSignup() {
  const { mutateAsync, ...mutation } = trpc.useMutation('auth.signup', {
    onSuccess: async (res: any) => {
      if (res.user_mx_guid) {
      }
    },
    onSettled: (error) => {
      //@ts-ignore
      if (error && error.data?.code === 'ERROR_CODE_EMAIL_NOT_VERIFIED') {
        setTimeout(() => {
          mutation.reset();
        }, 10000);
      }
    },
  });
  const { refetch: fetchUserProfile } = trpc.useQuery(['user-profile.get'], { enabled: false });
  const { refetch: refreshUser } = trpc.useQuery(['auth.me'], { enabled: false });

  return {
    ...mutation,
    signup: async (...args: ArgumentTypes<typeof mutateAsync>) => {
      await mutateAsync(...args);
      await refreshUser();
      return fetchUserProfile();
    },
  };
}
