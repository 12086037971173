import { trpc } from '@/utils/trpc';
// import * as amplitude from '@amplitude/analytics-browser';

export function useSignUpGoogleauth0() {
  const { mutateAsync: track } = trpc.useMutation('event.track');
  const signUpGoogleauth0Track = () => {
    track({
      event_type: 'Signup Googleauth0',
      event_data: { screen: 'Signup' },
    });
    // amplitude.track('Signup Googleauth0');
  };
  return {
    signUpGoogleauth0Track,
  };
}
